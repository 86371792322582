import React, { useEffect } from 'react';
import { shape } from 'prop-types';
import { Logger } from '@magalu/mixer-utils';
var CookieSecurity = function CookieSecurity(_ref) {
  var structure = _ref.structure;
  var _structure$config = structure.config,
    config = _structure$config === void 0 ? {} : _structure$config;
  var logger = Logger(config);
  useEffect(function () {
    var cookieSecurityScript = document.getElementById('cookieSecurity');
    if (!cookieSecurityScript) {
      var script = document.createElement('script');
      script.id = 'cookieSecurity';
      script.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js';
      script.setAttribute('data-tenant-uuid', '7923f79c-dd89-44df-bbc4-cc5a1f904fbf');
      script.setAttribute('data-domain-uuid', '0b729802-6884-47fb-b13a-da5dc5874f35');
      script.setAttribute('data-backend-url', 'https://app.securiti.ai');
      script.setAttribute('data-skip-css', 'false');
      script.fetchPriority = 'low';
      script.defer = true;
      var parentNode = document.head || document.body;
      parentNode.appendChild(script);
      script.addEventListener('load', function () {
        window.initCmp();
      });
    }
  }, []);
  useEffect(function () {
    return function () {
      try {
        var externalScript = document.getElementById('cookieSecurity');
        if (externalScript) externalScript.remove();

        document.querySelectorAll('head > script[src*=securiti]').forEach(function (elem) {
          return elem.remove();
        });

        document.querySelectorAll('body > div[class^=cc-]').forEach(function (elem) {
          return elem.remove();
        });
      } catch (e) {
        logger.error(e);
      }
    };
  }, [structure.asPath]);
  return React.createElement(React.Fragment, null);
};
CookieSecurity.defaultProps = {
  structure: {}
};
CookieSecurity.propTypes = {
  structure: shape({})
};
CookieSecurity.ssr = false;
export default CookieSecurity;