import React from 'react';
import { string, shape } from 'prop-types';
import getConfig from 'next/config';
var GoogleTagManagerHead = function GoogleTagManagerHead(_ref) {
  var id = _ref["static"].id;
  var _getConfig = getConfig(),
    config = _getConfig.publicRuntimeConfig;
  var DEV_MODE = config.environment === 'development';
  var setupContent = "\n    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({originalLocation: document.location.protocol+'//'+\n    document.location.hostname+document.location.pathname+document.location.search});\n    w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n    })(window,document,'script','dataLayer','".concat(id, "')\n  ");
  var setupDev = "(function (w, l) {w[l] = w[l] || [];})(window, 'dataLayer')";
  return React.createElement("script", {
    dangerouslySetInnerHTML: {
      __html: DEV_MODE ? setupDev : setupContent
    }
  });
};
GoogleTagManagerHead.ssr = true;
process.env.NODE_ENV !== "production" ? GoogleTagManagerHead.propTypes = {
  "static": shape({
    id: string
  }).isRequired
} : void 0;
export default GoogleTagManagerHead;