import React from 'react';
import { string, shape } from 'prop-types';
var SpotifyAdAnalytics = function SpotifyAdAnalytics(_ref) {
  var key = _ref["static"].key;
  var setupContent = "\n  (function(w, d){\n    var id='spdt-capture', n='script';\n    if (!d.getElementById(id)) {\n      w.spdt =\n        w.spdt ||\n        function() {\n          (w.spdt.q = w.spdt.q || []).push(arguments);\n        };\n      var e = d.createElement(n); e.id = id; e.async=1;\n      e.src = 'https://pixel.byspotify.com/ping.min.js';\n      var s = d.getElementsByTagName(n)[0];\n      s.parentNode.insertBefore(e, s);\n    }\n    w.spdt('conf', { key: '".concat(key, "' });\n  })(window, document);\n  ");
  return React.createElement("script", {
    dangerouslySetInnerHTML: {
      __html: setupContent
    }
  });
};
SpotifyAdAnalytics.ssr = true;
process.env.NODE_ENV !== "production" ? SpotifyAdAnalytics.propTypes = {
  "static": shape({
    key: string
  }).isRequired
} : void 0;
export default SpotifyAdAnalytics;