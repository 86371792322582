import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
var Preload = function Preload(_ref) {
  var _ref$static = _ref["static"];
  _ref$static = _ref$static === void 0 ? {} : _ref$static;
  var _ref$static$fonts = _ref$static.fonts,
    fonts = _ref$static$fonts === void 0 ? [] : _ref$static$fonts;
  return React.createElement(React.Fragment, null, fonts.map(function (_ref2) {
    var path = _ref2.path,
      ext = _ref2.ext;
    return React.createElement("link", {
      key: "preload-".concat(path, ".").concat(ext),
      rel: "preload",
      as: "font",
      type: "font/".concat(ext),
      href: "".concat(path, ".").concat(ext),
      crossOrigin: "true",
      defer: true
    });
  }));
};
Preload.defaultProps = {
  "static": {
    fonts: []
  }
};
process.env.NODE_ENV !== "production" ? Preload.propTypes = {
  "static": shape({
    fonts: arrayOf(shape({
      ext: string,
      path: string
    }))
  })
} : void 0;
Preload.ssr = true;
export default Preload;