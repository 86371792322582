import React from 'react';
import { string, shape } from 'prop-types';
var Stewie = function Stewie(_ref) {
  var url = _ref["static"].url;
  return React.createElement("script", {
    async: true,
    fetchpriority: "low",
    src: url
  });
};
process.env.NODE_ENV !== "production" ? Stewie.propTypes = {
  "static": shape({
    url: string
  })
} : void 0;
Stewie.defaultProps = {
  "static": {
    url: 'https://wx.mlcdn.com.br/site/shared/stewie/stewie-3.1.0.min.js'
  }
};
Stewie.ssr = true;
export default Stewie;