import React from 'react';
import { string, shape } from 'prop-types';
var AllInNewsletter = function AllInNewsletter(_ref) {
  var url = _ref["static"].url;
  return React.createElement("script", {
    defer: true,
    fetchpriority: "low",
    src: url
  });
};
AllInNewsletter.ssr = true;
process.env.NODE_ENV !== "production" ? AllInNewsletter.propTypes = {
  "static": shape({
    url: string
  })
} : void 0;
AllInNewsletter.defaultProps = {
  "static": {
    url: 'https://i.btg360.com.br/wf.js'
  }
};
export default AllInNewsletter;