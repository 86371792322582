
import React from 'react';
import { string, shape } from 'prop-types';
var GooglePublisherTag = function GooglePublisherTag(_ref) {
  var url = _ref["static"].url;
  var content = "\n    if(!window.googletag){\n      window.googletag = window.googletag || { cmd: [] };\n      const { googletag } = window;\n      googletag.cmd = googletag.cmd || [];\n\n      googletag.cmd.push(function init() {\n        try {\n          googletag.pubads().enableSingleRequest();\n          googletag.pubads().disableInitialLoad();\n          googletag.pubads().collapseEmptyDivs();\n          googletag.enableServices();\n        } catch (err) {\n          console.error(\"[googletag] - error: \", err);\n        }\n      });\n    }\n  ";
  return React.createElement(React.Fragment, null, React.createElement("script", {
    name: "google-publisher-tag",
    async: true,
    src: url
  }), React.createElement("script", {
    name: "googletag"
    ,
    dangerouslySetInnerHTML: {
      __html: content
    }
  }));
};
GooglePublisherTag.ssr = true;
process.env.NODE_ENV !== "production" ? GooglePublisherTag.propTypes = {
  "static": shape({
    url: string
  })
} : void 0;
GooglePublisherTag.defaultProps = {
  "static": {
    url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
  }
};
export default GooglePublisherTag;