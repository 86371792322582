import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["href", "rel"];
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { slug } from '@magalu/mixer-utils';
var Favicon = function Favicon(_ref) {
  var _ref$static = _ref["static"];
  _ref$static = _ref$static === void 0 ? {} : _ref$static;
  var _ref$static$icons = _ref$static.icons,
    icons = _ref$static$icons === void 0 ? [] : _ref$static$icons;
  return icons == null ? void 0 : icons.map(function (_ref2) {
    var href = _ref2.href,
      rel = _ref2.rel,
      props = _objectWithoutProperties(_ref2, _excluded);
    return href && rel && React.createElement("link", _extends({
      key: "favicon-".concat(slug(rel)),
      rel: rel,
      href: href
    }, props));
  });
};
Favicon.ssr = true;
process.env.NODE_ENV !== "production" ? Favicon.propTypes = {
  "static": shape({
    icons: arrayOf(shape({
      href: string,
      rel: string,
      size: string,
      type: string
    }))
  })
} : void 0;
export default Favicon;