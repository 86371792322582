
import React from 'react';
var RTBHouse = function RTBHouse() {
  return React.createElement("script", {
    dangerouslySetInnerHTML: {
      __html: "\n      (function (w,d,dn,t){w[dn]=w[dn]||[];w[dn].push({eventType:'init',value:t,dc:'us'});\n      var f=d.getElementsByTagName('script')[0],\n      c=d.createElement('script');\n      c.async=true;\n      c.fetchPriority=\"low\";\n      c.src='https://tags.creativecdn.com/msWukR4UlwKQouZSY8IT.js';\n      f.parentNode.insertBefore(c,f);})(window,document,'rtbhEvents','msWukR4UlwKQouZSY8IT');\n      "
    }
  });
};
RTBHouse.ssr = true;
export default RTBHouse;