import React from 'react';
import { shape, string } from 'prop-types';
var Hotjar = function Hotjar(_ref) {
  var id = _ref["static"].id;
  return React.createElement("script", {
    dangerouslySetInnerHTML: {
      __html: "\n      (function(h,o,t,j,a,r){\n        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n        h._hjSettings={hjid:".concat(id, ",hjsv:6};\n        a=o.getElementsByTagName('head')[0];\n        r=o.createElement('script');r.async=1;r.fetchPriority=\"low\";\n        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n        a.appendChild(r);\n    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');")
    }
  });
};
Hotjar.ssr = true;
process.env.NODE_ENV !== "production" ? Hotjar.propTypes = {
  "static": shape({
    id: string.isRequired
  }).isRequired
} : void 0;
export default Hotjar;