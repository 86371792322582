import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["type"];
import React from 'react';
import { shape, string } from 'prop-types';
import JSONLD from '@magalu/stereo-ui/atoms/JsonLD';
import { productSchema, faqSchema } from '@magalu/stereo-ui/atoms/JsonLD/schemas';
var JsonLd = function JsonLd(_ref) {
  var _data$questions2;
  var data = _ref.data,
    _ref$static = _ref["static"],
    type = _ref$static.type,
    props = _objectWithoutProperties(_ref$static, _excluded),
    structure = _ref.structure;
  var product = data.product,
    productRating = data.productRating;
  var config = structure.config;
  var jsonProps = function jsonProps(t) {
    var _data$questions;
    switch (t) {
      case 'Product':
        return productSchema({
          product: _extends({}, product, {
            title: product == null ? void 0 : product.title
          }),
          productRating: productRating
        }, "".concat(config == null ? void 0 : config.domain, "/"));
      case 'FAQPage':
        return faqSchema((data == null ? void 0 : (_data$questions = data.questions) == null ? void 0 : _data$questions.items) || []);
      default:
        return _extends({}, props);
    }
  };
  var notReturnEmptyFaq = !!(type === 'FAQPage' && !(data == null ? void 0 : (_data$questions2 = data.questions) == null ? void 0 : _data$questions2.items.length));
  return notReturnEmptyFaq ? null : React.createElement(JSONLD, {
    type: type,
    schema: jsonProps(type)
  });
};
process.env.NODE_ENV !== "production" ? JsonLd.propTypes = {
  data: shape({}),
  "static": shape({
    type: string.isRequired
  }).isRequired,
  structure: shape({})
} : void 0;
JsonLd.defaultProps = {
  data: {},
  structure: {}
};
JsonLd.ssr = true;
export default JsonLd;