import React from 'react';
var Radware = function Radware() {
  return React.createElement("script", {
    id: "radware"
    ,
    dangerouslySetInnerHTML: {
      __html: "(function (w, d, e, u, c, g, a, b) {\n        w['SSJSConnectorObj'] = w['SSJSConnectorObj'] || {\n          ss_cid: c,\n          domain_info: 'auto',\n        };\n        w[g] = function (i, j) {\n          w['SSJSConnectorObj'][i] = j;\n        };\n        a = d.createElement(e);\n        a.async = true;\n        a.fetchPriority = \"low\";\n        a.src = u;\n        b = d.getElementsByTagName(e)[0];\n        b.parentNode.insertBefore(a, b);\n      })(\n        window,\n        document,\n        'script',\n        'https://cdn.perfdrive.com/aperture/aperture.js',\n        'bna1',\n        'ssConf'\n      );\n      ssConf('c1', 'https://cas.avalon.perfdrive.com');"
    }
  });
};
Radware.ssr = true;
export default Radware;