import React from 'react';
import { string, shape } from 'prop-types';
var Criteo = function Criteo(_ref) {
  var url = _ref["static"].url;
  return React.createElement("script", {
    defer: true,
    fetchpriority: "low",
    src: url
  });
};
Criteo.ssr = true;
process.env.NODE_ENV !== "production" ? Criteo.propTypes = {
  "static": shape({
    url: string
  })
} : void 0;
Criteo.defaultProps = {
  "static": {
    url: 'https://static.criteo.net/js/ld/ld.js'
  }
};
export default Criteo;