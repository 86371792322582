import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
var Preconnect = function Preconnect(_ref) {
  var _ref$static = _ref["static"];
  _ref$static = _ref$static === void 0 ? {} : _ref$static;
  var _ref$static$list = _ref$static.list,
    list = _ref$static$list === void 0 ? [] : _ref$static$list;
  return React.createElement(React.Fragment, null, list.map(function (item) {
    return React.createElement("link", {
      key: "preconnect-".concat(item),
      rel: "preconnect",
      href: item,
      crossOrigin: "true",
      defer: true
    });
  }));
};
Preconnect.defaultProps = {
  "static": {
    list: []
  }
};
process.env.NODE_ENV !== "production" ? Preconnect.propTypes = {
  "static": shape({
    list: arrayOf(string)
  })
} : void 0;
Preconnect.ssr = true;
export default Preconnect;