import React from 'react';
import { string, shape } from 'prop-types';
var GoogleTagManagerNoscript = function GoogleTagManagerNoscript(_ref) {
  var id = _ref["static"].id;
  return React.createElement("noscript", {
    dangerouslySetInnerHTML: {
      __html: "<noscript><iframe src=\"https://www.googletagmanager.com/ns.html?id=".concat(id, "\" width=\"0\" heigth=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>")
    }
  });
};
GoogleTagManagerNoscript.ssr = true;
GoogleTagManagerNoscript.displayName = 'GoogleTagManagerNoscript';
process.env.NODE_ENV !== "production" ? GoogleTagManagerNoscript.propTypes = {
  "static": shape({
    id: string
  }).isRequired
} : void 0;
export default GoogleTagManagerNoscript;